import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalState {
  theme: 'light' | 'dark' | 'blue' | 'system';
  language: 'zh-CN' | 'en-US';
  network: 'mainnet' | 'testnet';
}

const initialState: GlobalState = {
  theme: localStorage.getItem('theme') as 'light' | 'dark' | 'blue' | 'system',
  language: localStorage.getItem('language') as 'zh-CN' | 'en-US' || 'zh-CN',
  network: localStorage.getItem('network') as 'mainnet' | 'testnet' || 'mainnet',
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<'light' | 'dark' | 'blue' | 'system'>) {
      state.theme = action.payload;
      localStorage.setItem('theme', action.payload);
      
      // Remove all theme classes first
      document.body.classList.remove('t-dark-mode', 't-blue-mode');
      
      if (action.payload === 'system') {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (prefersDark) {
          document.body.classList.add('t-dark-mode');
        }
      } else if (action.payload === 'dark') {
        document.body.classList.add('t-dark-mode');
      } else if (action.payload === 'blue') {
        document.body.classList.add('t-blue-mode');
      }
    },
    setLanguage(state, action: PayloadAction<'zh-CN' | 'en-US'>) {
      state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },
    setSearchKeyword(state, action: PayloadAction<string>) {
      // state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },
    setNetwork(state, action: PayloadAction<'mainnet' | 'testnet'>) {
      state.network = action.payload;
      localStorage.setItem('network', action.payload);
    },
  },
});

export const { setTheme, setLanguage, setSearchKeyword, setNetwork } = globalSlice.actions;
export default globalSlice.reducer;
