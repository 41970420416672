import React from 'react';
import { Card, Button, Space } from 'tdesign-react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { ErrorCircleFilledIcon } from 'tdesign-icons-react';
import { useTranslation } from 'react-i18next';

const Error: React.FC = () => {
  const navigate = useNavigate();
  const error = useRouteError() as Error;
  const { t } = useTranslation();

  return (
    <Card>
      <Space direction="vertical" align="center" style={{ width: '100%', padding: '32px 0' }}>
        <ErrorCircleFilledIcon style={{ fontSize: '48px', color: 'var(--td-error-color)' }} />
        <h3 style={{ margin: '8px 0', fontSize: '20px' }}>{t('common.error.title')}</h3>
        <p style={{ margin: '0 0 24px', color: 'var(--td-text-color-secondary)' }}>
          {error?.message || t('common.error.description')}
        </p>
        <Space>
          <Button onClick={() => navigate(-1)}>
            {t('common.back')}
          </Button>
          <Button theme="default" onClick={() => window.location.reload()}>
            {t('common.error.retry')}
          </Button>
        </Space>
      </Space>
    </Card>
  );
};

export default Error;
