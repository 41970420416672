import React from "react";
import { Card, Table, Input, Space } from "tdesign-react";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "tdesign-icons-react";
import "./styles/index.css";

const ContractList: React.FC = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("contract.list.columns.address"),
      colKey: "address",
      width: 200,
    },
    {
      title: t("contract.list.columns.creator"),
      colKey: "creator",
      width: 200,
    },
    {
      title: t("contract.list.columns.txCount"),
      colKey: "transactions",
      width: 100,
    },
    {
      title: t("contract.list.columns.balance"),
      colKey: "balance",
      width: 150,
    },
    {
      title: t("contract.list.columns.createTime"),
      colKey: "createdAt",
      width: 150,
    },
  ];

  return (
    <div className="contract-list-container">
      <Card bordered={false }>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            prefixIcon={<SearchIcon />}
            placeholder={t("contract.search.placeholder")}
            style={{ width: "300px" }}
          />
          <Table
            columns={columns}
            data={[]}
            loading={false}
            pagination={{
              total: 0,
              pageSize: 10,
              current: 1,
            }}
            rowKey="address"
          />
        </Space>
      </Card>
    </div>
  );
};

export default ContractList;