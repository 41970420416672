import React from 'react';
import { Menu } from 'tdesign-react';
import { useTranslation } from 'react-i18next';
import ThemeToggleButton from '../../components/ThemeToggle';
import LanguageToggleButton from '../../components/LanguageToggleButton';
import NetworkToggleButton from '../../components/NetworkToggle';

const { HeadMenu, MenuItem } = Menu;

// 定义顶部菜单项
const MENU_ITEMS_TOP = [
  {
    path: '/',
    label: 'YXL',
    value: '10000000',
  },
  {
    path: '/blocks',
    label: 'GAS',
    value: '10000',
  },
];

interface TopMenuProps {
  theme: 'dark' | 'light' | 'system' | 'blue';
}

const TopMenu: React.FC<TopMenuProps> = ({ theme }) => {
  const { t } = useTranslation();

  const operationsDarkTop = () => (
    <div className="tdesign-demo-menu__operations--dark">
      <ThemeToggleButton />
      <LanguageToggleButton />
      <NetworkToggleButton />
    </div>
  );

  return (
    <HeadMenu
      className="tdesign--menu--dark"
      theme={theme === 'dark' ? 'dark' : 'light'}
      style={{
        height: '50px',
        borderBottom: '1px solid var(--td-component-border)',
      }}
      operations={operationsDarkTop()}
    >
      {MENU_ITEMS_TOP.map((item) => (
        <MenuItem
          style={{
            height: '40px',
            margin: 0,
            padding: 0,
            paddingRight: 10,
            color: theme === 'dark' ? 'light' : 'dark',
            backgroundColor: 'transparent',
          }}
          key={item.path}
          value={item.path}
        >
          {t(item.label)} : {t(item.value)}
        </MenuItem>
      ))}
    </HeadMenu>
  );
};

export default TopMenu;