import React from 'react';
import { Button, Dropdown, DropdownOption } from 'tdesign-react';
import { MoonIcon, SunnyIcon, DesktopIcon } from 'tdesign-icons-react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { setTheme } from '../../store/slices/globalSlice';
import { useTranslation } from 'react-i18next';

const ThemeToggleButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.global.theme);
  const { t } = useTranslation();

  const options = [
    { content: t('theme.light'), value: 'light', icon: <SunnyIcon /> },
    { content: t('theme.dark'), value: 'dark', icon: <MoonIcon /> },
    { content: t('theme.blue'), value: 'blue', icon: <SunnyIcon /> },
    { content: t('theme.system'), value: 'system', icon: <DesktopIcon /> },
  ];

  const handleThemeChange = (ropdownItem: DropdownOption) => {
    dispatch(setTheme(ropdownItem.value as 'light' | 'dark' | 'blue' | 'system'));
    document.body.classList.toggle('t-dark-mode');
  };

  const getCurrentIcon = () => {
    switch (theme) {
      case 'light':
        return <SunnyIcon />;
      case 'dark':
        return <MoonIcon />;
      case 'blue':
        return <SunnyIcon />;
      case 'system':
        return <DesktopIcon />;
      default:
        return <SunnyIcon />;
    }
  };

  return (
    <Dropdown options={options} onClick={handleThemeChange}>
      <Button
        shape="circle"
        variant="text"
        icon={getCurrentIcon()}
        theme={theme === 'light' ? 'default' : 'primary'}
      />
    </Dropdown>
  );
};

export default ThemeToggleButton;
