import dayjs from 'dayjs';
import i18n from 'i18n';

/**
 * 将时间戳格式化为字符串日期时间
 * @param timestamp - Unix 时间戳(毫秒)
 * @returns 格式化后的日期时间字符串，格式为 'YYYY-MM-DD HH:mm:ss'
 */
export const formatTime = (timestamp: string | number): string => {
  return dayjs(timestamp).format('YYYY/MM/DD HH:mm:ss');
};
export const formatTimestamp = (timestamp: number): string => {
  return dayjs(timestamp / 1000).format('YYYY/MM/DD HH:mm:ss');
};

/**
 * 将时间戳格式化为相对时间描述
 * @param timestamp - Unix 时间戳(毫秒)
 * @returns 格式化后的相对时间字符串，如"刚刚"、"5分钟前"、"2小时前"、"3天前"或"YYYY-MM-DD"格式的日期
 */
export const formatTimeAgo = (timestamp: number): string => {
  const { t } = i18n;
  const now = dayjs();
  const time = dayjs(timestamp / 1000);
  const diffMinutes = now.diff(time, 'minute');
  const diffSeconds = now.diff(time, 'second');


  if (diffMinutes < 1) return t('time.seconds_ago', { seconds: diffSeconds });
  if (diffMinutes < 60) return t('time.minutes_ago', { minutes: diffMinutes });

  const diffHours = now.diff(time, 'hour');
  if (diffHours < 24) return t('time.hours_ago', { hours: diffHours });

  const diffDays = now.diff(time, 'day');
  if (diffDays < 30) return t('time.days_ago', { days: diffDays });

  return time.format(t('time.date_format'));
};

// 格式化金额
export const formatAmount = (amount: string, locale: string = i18n.language): string => {
  const num = parseFloat(amount);
  if (isNaN(num)) return '0';

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: locale === 'zh-CN' ? 'CNY' : 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 8
  });

  return formatter.format(num);
};

// 缩短哈希显示
export const shortenHash = (hash: string): string => {
  if (!hash || hash.length < 10) return hash;
  return `${hash.slice(0, 9)}...${hash.slice(-6)}`;
};
export const formatHash = (hash: string): string => {
  if (!hash || hash.length < 10) return hash;
  return `${hash.slice(0, 9)}...${hash.slice(-6)}`;
};

// 格式化区块大小
export const formatSize = (bytes: number): string => {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
  return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
};

// 格式化 Gas
export const formatGas = (gas: number): string => {
  return gas.toLocaleString('en-US') + ' Gas';
};

// 格式化百分比
export const formatPercent = (value: number): string => {
  return `${(value * 100).toFixed(2)}%`;
};

// 格式化数字
export const formatNumber = (num: number, locale: string = 'en-US'): string => {
  return num.toLocaleString(locale);
};

// 格式化数字
export const formatYxl = (num: number | string, locale: string = 'en-US'): string => {
  let number = 0;
  if (typeof num ==='string') {
    number = parseFloat(num)/10000000;
  } else {
    number = num / 10000000;
  }
  return number.toLocaleString(locale) + ' ' + 'Gas';
};

// 格式化数字
export const formatUgas = (num: number | string, locale: string = 'en-US'): string => {
  let number = 0;
  if (typeof num === 'string') {
    number = parseFloat(num);
  } else {
    number = num;
  }
  return number.toLocaleString(locale) + ' ' + 'UGas';
};


// 格式化数字
export const formatAssets = (num: number, code: string, locale: string = 'en-US'): string => {
  let number = num/10000000;
  return number.toLocaleString(locale) + ' ' + code;
};
