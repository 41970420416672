import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu } from 'tdesign-react';
import { useTranslation } from 'react-i18next';
import { UserIcon } from 'tdesign-icons-react';
import type { MenuValue } from 'tdesign-react';

const { HeadMenu, MenuItem } = Menu;

// 定义菜单项
const MENU_ITEMS = [
  {
    path: '/',
    label: 'header.menu.home',
  },
  {
    path: '/blocks',
    label: 'header.menu.blocks',
  },
  {
    path: '/transactions',
    label: 'header.menu.transactions',
  },
  {
    path: '/accounts',
    label: 'header.menu.accounts',
  },
  {
    path: '/tokens',
    label: 'header.menu.tokens',
  },
  {
    path: '/contracts',
    label: 'header.menu.contracts',
  },
];

interface HeaderMenuProps {
    theme: 'dark' | 'light' | 'system' | 'blue';
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ theme }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMenuChange = React.useCallback(
    (value: MenuValue) => {
      if (typeof value === 'string') {
        navigate(value);
      }
    },
    [navigate]
  );

  const operationsDark = () => (
    <div className="tdesign-demo-menu__operations--dark">
      <Button
        variant="text"
        shape="square"
        icon={<UserIcon />}
        theme ={theme === 'light' ? 'default' : 'primary'}
      />
    </div>
  );

  const logo = (
    <div
      style={{
        fontSize: '20px',
        fontWeight: 'bold',
        color: theme === 'dark' ? '#fff' : 'inherit',
      }}
    >
      {t('header.title')}
    </div>
  );

  return (
    <HeadMenu
      value={location.pathname}
      onChange={handleMenuChange}
      theme={theme === 'dark' ? 'dark' : 'light'}
      logo={logo}
      operations={operationsDark()}
      style={{
        marginBottom: 0,
      }}
    >
      {MENU_ITEMS.map((item) => (
        <MenuItem key={item.path} value={item.path}>
          {t(item.label)}
        </MenuItem>
      ))}
    </HeadMenu>
  );
};

export default HeaderMenu;