import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "tdesign-react";
import { useAppSelector } from "../hooks/useAppSelector";
import ErrorBoundary from "../components/ErrorBoundary";
import { useTranslation } from "react-i18next";
import HeaderMenu from "./component/HeaderMenu";
import TopMenu from "./component/TopMenu";

const { Header, Content, Footer } = Layout;

const MainLayout: React.FC = () => {
  const theme = useAppSelector((state) => state.global.theme);
  const { t } = useTranslation();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          padding: 0,
          height: "auto",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <TopMenu theme={theme} />
        <HeaderMenu theme={theme} />
      </Header>

      {/* 主内容区域 */}
      <Content
        style={{
          padding: "0",
          minHeight: "calc(100vh - 100px)",
          backgroundColor: theme === "dark" ? "#1a1a1a" : "#f5f5f5",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Content>
      {/* <Footer
        style={{
          textAlign: "right",
          padding: "10px 24px",
          backgroundColor: theme === "dark" ? "#1a1a1a" : "#f5f5f5",
          color: theme === "dark" ? "#fff" : "#333",
        }}
      >
        {t("footer.copyright")}
      </Footer> */}
    </Layout>
  );
};

export default memo(MainLayout);
