import React from 'react';
import { Loading } from 'tdesign-react';

const LoadingPage: React.FC = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'var(--td-bg-color-page)',
      }}
    >
      <Loading
        loading={true}
        showOverlay
        size="large"
        text="加载中..."
      />
    </div>
  );
};

export default LoadingPage;
