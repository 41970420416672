import React from 'react';
import { Card, Button, Space } from 'tdesign-react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from 'tdesign-icons-react';
import { useTranslation } from 'react-i18next';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card>
      <Space direction="vertical" align="center" style={{ width: '100%', padding: '32px 0' }}>
        <SearchIcon style={{ fontSize: '48px', color: 'var(--td-warning-color)' }} />
        <h3 style={{ margin: '8px 0', fontSize: '20px' }}>404 Not Found</h3>
        <p style={{ margin: '0 0 24px', color: 'var(--td-text-color-secondary)' }}>
          {t('common.error.notFound')}
        </p>
        <Button theme="primary" onClick={() => navigate('/')}>
          {t('common.back')}
        </Button>
      </Space>
    </Card>
  );
};

export default NotFound;
