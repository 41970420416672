import React, { Component, ErrorInfo, ReactNode } from 'react';
// import { Result, Button } from 'tdesign-react';
// import { ErrorCircleIcon } from 'tdesign-icons-react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        // <Result
        //   theme="error"
        //   icon={<ErrorCircleIcon />}
        //   title="应用程序错误"
        //   description={this.state.error?.message || '发生了未知错误'}
        //   actions={
        //     <Button theme="danger" onClick={() => window.location.reload()}>
        //       刷新页面
        //     </Button>
        //   }
        // />
        <div>
          <h1>应用程序错误</h1>
          <p>{this.state.error?.message || '发生了未知错误'}</p>
          <button onClick={() => window.location.reload()}>刷新页面</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
