import React from 'react';
import { Button, Dropdown, DropdownOption } from 'tdesign-react';
import { ActivityIcon } from 'tdesign-icons-react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useAppSelector';

const LanguageToggleButton: React.FC = () => {
  const theme = useAppSelector((state) => state.global.theme);
  const { i18n } = useTranslation();

  const options = [
    {
      content: '简体中文',
      value: 'zh-CN',
    },
    {
      content: 'English',
      value: 'en-US',
    },
  ];

  const handleLanguageChange = (dropdownItem: DropdownOption) => {
    if (typeof dropdownItem.value === 'string') {
      i18n.changeLanguage(dropdownItem.value);
    }
  };

  return (
    <Dropdown
      options={options}
      onClick={handleLanguageChange}
      trigger="click"
      popupProps={{ overlayStyle: { width: '100px' } }}
    >
      <Button
        shape="circle"
        variant="text"
        icon={<ActivityIcon />}
        theme= {theme === 'light' ? 'default' : 'primary'}
      />
    </Dropdown>
  );
};

export default LanguageToggleButton;
