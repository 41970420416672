import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { MessagePlugin } from 'tdesign-react';

// 创建 axios 实例
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://openapi.aospub.com',
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在这里可以添加认证信息等
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    const { data } = response;
    // MessagePlugin.success(data || '请求成功');
    // 假设后端返回格式为 { code: number, result: any, message: string }
    if (data.code === 0) {
      return data.result;
    }
    
    MessagePlugin.error(data.desc || '请求失败');
    return Promise.reject(new Error(data.desc || '请求失败'));
  },
  (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 304:
          // 数据重复请求使用缓存;
          break;
        case 401:
          MessagePlugin.error('未授权，请重新登录');
          // 可以在这里处理登录过期逻辑
          break;
        case 403:
          MessagePlugin.error('拒绝访问');
          break;
        case 404:
          MessagePlugin.error('请求错误，未找到该资源');
          break;
        case 500:
          MessagePlugin.error('服务器错误');
          break;
        default:
          MessagePlugin.error('网络错误');
      }
    } else {
      if (error.message.includes('timeout')) {
        MessagePlugin.error('请求超时');
      } else {
        MessagePlugin.error('网络错误');
      }
    }
    return Promise.reject(error);
  }
);

// 封装 GET 请求
export function get<T = any>(url: string, params?: any, config?: AxiosRequestConfig): Promise<T> {
  return instance.get(url, { params, ...config });
}

// 封装 POST 请求
export function post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  return instance.post(url, data, config);
}

// 封装 PUT 请求
export function put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  return instance.put(url, data, config);
}

// 封装 DELETE 请求
export function del<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return instance.delete(url, config);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get,
  post,
  put,
  delete: del,
};
