import request from '../../utils/request';
import chainRequest from '../../utils/chainRequest';
import type { AssetsListParams, Asset, Assets } from '../../types/api';

export const assetsService = {
  // 获取账户列表
  getAssets: async (params: AssetsListParams) => {
    // console.log('getAssets', params);
    const res = await request.get<Assets>('/api/v1/assets', params);
    // console.log('getAssets', res);
    return res;
  },

  // 获取账户详情
  getAsset: async (id: string) => {
    // console.log('getAsset', id);
    // const params = {
    //   address: id,
    // }
    // const res = await chainRequest.get<any>('getAccount', params);

    const res = await request.get<Asset>(`/api/v1/asset/${id}`);
    // console.log('getAsset', res);
    return res;
  },
};
