import React from "react";
import { Card, Table, Space, Input } from "tdesign-react";
import { useTranslation } from "react-i18next";

interface TokenHoldersProps {
  tokenAddress: string;
}

const TokenHolders: React.FC<TokenHoldersProps> = ({ tokenAddress }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState("");

  const columns = [
    {
      title: t("token.holders.address"),
      colKey: "address",
      width: 300,
    },
    {
      title: t("token.holders.balance"),
      colKey: "balance",
      width: 150,
    },
    {
      title: t("token.holders.percentage"),
      colKey: "percentage",
      width: 150,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {/* <Card bordered={false}> */}
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            placeholder={t("token.holders.searchPlaceholder")}
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
          />
          <Table
            columns={columns}
            data={[]}
            loading={false}
            pagination={{
              total: 0,
              pageSize: 10,
              current: 1,
            }}
            rowKey="address"
          />
        </Space>
      {/* </Card> */}
    </Space>
  );
};

export default TokenHolders;