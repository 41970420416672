import React, { useEffect } from "react";
import { Card, Table, Input, Space } from "tdesign-react";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "tdesign-icons-react";
import "./styles/index.css";
import { Asset } from "types/api";
import  {assetsService} from "services/modules/assets";
import { formatAssets, formatHash, formatYxl } from "utils/format";
import { Link } from "react-router-dom";

const TokenList: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [tokens, setTokens] = React.useState<Asset[]>([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        setLoading(true);
        const { records, total } = await assetsService.getAssets({
          pageNum: pagination.current,
          pageSize: pagination.pageSize,
        });
        setTokens(records);
        setPagination(prev => ({
          ...prev,
          total,
        }));
      } catch (error) {
        console.error('Failed to fetch tokens:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchTokens();
  }, []);

  const columns = [
    {
      title: t("token.list.name"),
      colKey: "name",
      width: 200,
      cell: ({ row }: { row: Asset }) => (row.code),
    },
    {
      title: t("token.list.symbol"),
      colKey: "code",
      width: 100,
      cell: ({ row }: { row: Asset }) => (<Link to={`/tokens/${row._id}`}>{row.code}</Link>),
    },
    {
      title: t("token.list.totalSupply"),
      colKey: "amount",
      width: 200,
      // align: "right",
      cell: ({ row }: { row: Asset }) => (formatAssets(row.amount, row.code)),
    },
    {
      title: t("token.list.issuer"),
      colKey: "issuer",
      width: 200,
      cell: ({ row }: { row: Asset }) => (<Link to={`/accounts/${row.issuer}`}>{formatHash(row.issuer)}</Link>),
    },
    {
      title: t("token.list.holders"),
      colKey: "holders",
      width: 100,
    },
    {
      title: t("token.list.transfers"),
      colKey: "transfers",
      width: 100,
    },
  ];

  return (
    <div className="token-list-container">
      <Card bordered={false }>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            prefixIcon={<SearchIcon />}
            placeholder={t("token.search.placeholder")}
            style={{ width: "300px" }}
          />
          <Table
          horizontalScrollAffixedBottom
            columns={columns}
            data={tokens}
            loading={loading}
            pagination={{
              total: pagination.total,
              pageSize: pagination.pageSize,
              current: pagination.current,
              onChange: (pageInfo: { current: number; pageSize: number }) => setPagination(prev => ({
                ...prev,
                current: pageInfo.current,
                pageSize: pageInfo.pageSize,
              })),
            }}
            rowKey="code"
          />
        </Space>
      </Card>
    </div>
  );
};

export default TokenList;


